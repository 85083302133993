import React, { useState } from "react";
import "./VideoGalleryContent.css";

const videoData = [
  {
    id: 1,
    title: "Research @ IITM",
    videoUrl: "https://www.youtube.com/embed/nTIlQxjkpvs", // Example YouTube URL
    thumbnail: "assets/videogallery/viksitbharat.png",
  },
  {
    id: 2,
    title: "CFI",
    videoUrl: "https://www.youtube.com/embed/i5mZlZ2dZoo", // Example YouTube URL
    thumbnail: "assets/videogallery/CFI.png",
  },
  {
    id: 3,
    title: "THE ENERGY CONSORTIUM",
    videoUrl: "https://www.youtube.com/embed/c66AyXXUeew", // Example YouTube URL
    thumbnail: "assets/videogallery/ECIITM.png",
  },
  {
    id: 4,
    title: "ICCW",
    videoUrl: "https://www.youtube.com/embed/SsxHKn5ogRU", // Example YouTube URL
    thumbnail: "assets/videogallery/ICCW.png",
  },
  {
    id: 5,
    title: "NPTEL",
    videoUrl: "https://www.youtube.com/embed/lNZgANG7lVo", // Example YouTube URL
    thumbnail: "assets/videogallery/nptel.png",
  },
  {
    id: 6,
    title: "SCHOOL OF SUSTAINABILITY",
    videoUrl: "https://www.youtube.com/embed/yB4zLZqwMCQ", // Example YouTube URL
    thumbnail: "assets/videogallery/SOS.png",
  },
  {
    id: 7,
    title: "DATA SCIENCE & AI",
    videoUrl: "https://www.youtube.com/embed/RJiHCv_TXVM", // Example YouTube URL
    thumbnail: "assets/videogallery/datascienceai.png",
  },
  {
    id: 8,
    title: "Vision for Technology-Driven CSR",
    videoUrl: "https://www.youtube.com/embed/xFt_uUL3ey8", // Example YouTube URL
    thumbnail: "assets/videogallery/csrvision.png",
  },
  // Add more video objects as needed
];

function VideoGalleryContent() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  return (
    <>
      <div className="video-gallery">
        <h1 className="video-gallery-heading">Video Gallery</h1>

        <div className="gallery">
          {videoData.map((video) => (
            <div
              key={video.id}
              className="video-item"
              onClick={() => handleVideoClick(video)}
            >
              <img
                src={video.thumbnail}
                alt={video.title}
                className="thumbnail"
              />
              <p>{video.title}</p>
            </div>
          ))}
        </div>

        {selectedVideo && (
          <div className="modal" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button
                className="close-button"
                onClick={handleCloseModal}
                style={{ position: "relative" }}
              >
                &times;
              </button>
              <iframe
                width="600"
                height="400"
                src={selectedVideo.videoUrl}
                title={selectedVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default VideoGalleryContent;
