import React from 'react'
import './NewsletterContent.css'

class NewsletterContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            visible: 12,
            error: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
            return { visible: prev.visible + 4 };
        });
    }

    componentDidMount() {
       // fetch("http://localhost/sociityApi/loadmore1.php")
        fetch("https://sociity.in/sociityApi/loadmore1.php")
            .then(res => res.json())
            .then(res => { this.setState({ items: res }); })
            .catch(error => {
                console.error(error);
                this.setState({
                    error: true
                });
            });
    }
    render() {
        return (
            <>
                <section className='newsletter-section'>
                    <div className="rs-breadcrumbs breadcrumbs-overlay">
                        <div className="breadcrumbs-text white-color">
                            <h1 className="newsletter-page-title">IIT Madras CSR Newsletter</h1>
                        </div>
                    </div>

                    <div id="cards_landscape_wrap-2">
                        <div className="container">
                            <div className="row">
                                {this.state.items.slice(0, this.state.visible).map((item, index) => {
                                    return (
                                        <div key={item.id} className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                            <a href={item.npdf} target='_blank' rel="noreferrer">
                                                <div className="card-flyer">
                                                    <div className="text-box">
                                                        <div className="image-box">
                                                            <img src={item.nimage} alt="" />
                                                        </div>
                                                        <div className="text-container">
                                                            <h6>{item.ndate}</h6>
                                                            <p>Click here to read</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                            {this.state.visible < this.state.items.length &&
                                <button style={{ marginTop: "15px" }} onClick={this.loadMore} type="button" className="load-more readon orange-btn main-home">LOAD MORE</button>
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default NewsletterContent