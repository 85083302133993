import React from "react";

function Header() {     

    return (
        <header className="header01 isSticky">
            <div className="container-fluid largeContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar01">
                            <div className="logo1">
                                <a href="/">
                                    <img src="assets/images/logo.png" alt="Sociity"/>
                                </a>
                            </div>
                            <a href="/" className="menu_btn"><i className="twi-bars2"></i></a>
                            <nav className="mainMenu">
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>  
                                    <li>
                                    <a href="#/health">Opportunities</a>  
                                    </li>    
                                                     
                                    {/* <li className="menu-item-has-children">
                                        <a href="#/projects">CSR Opportunities</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/health">Open Projects</a></li>
                                            <li><a href="#/noteworthy">Noteworthy Projects</a></li>
                                            <li><a href="#/researchareas">Research Areas @ IITM</a></li>
                                        </ul>
                                    </li> */}
                                    <li className="menu-item-has-children">
                                        <a href="/">Impact</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/noteworthy">Completed Projects</a></li>                                            
                                            <li><a href="/" alt="">IITM in News</a></li>
                                            <li><a href="/">Centre of Excellence</a></li>
                                        </ul>
                                    </li> 

                                    <li className="menu-item-has-children">
                                        <a href="/">Resources</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/newsletter" alt="">Newsletter</a></li>
                                            <li><a href="#/agr" alt="">Annual Giving Report</a></li>
                                            <li><a href="#/ctb" alt="">Coffee Table Book</a></li>
                                            <li><a href="#/csr-news" alt="">CSR News</a></li>
                                            <li><a href="#/ideas-impact" alt="">Ideas & Impact</a></li>
                                            {/* <li><a href="#/socialimpact">Social Impact Reports</a></li>                                      */}
                                            <li><a href="#/videogallery">Gallery</a></li>
                                        </ul>
                                    </li> 
                                    <li>
                                    <a href="#/newpartners">Corporate Partners</a>  
                                    </li>  

                                    <li className="menu-item-has-children">
                                        <a href="/">Events</a>
                                        <ul className="sub-menu">
                                            <li><a href="/">Upcoming Events</a></li>
                                            <li><a href="/">Recent Events</a></li>
                                           {/* <li><a href="#/events">Events</a></li>
                                             <li><a href="/">Blogs</a></li>*/}
                                        </ul>
                                    </li>  
                                    <li className="menu-item-has-children">
                                        <a href="/">About Us</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/team">Our Team</a></li>
                                            <li><a href="#/ourwork">Our Work</a></li>                                            
                                        </ul>
                                    </li>    
                                    {/* <li>
                                    <a href="#/partnerwithiitm">Partner with IITM</a>  
                                    </li>   */}
                                </ul>
                            </nav>
                            <div className="accessNav">
                                <a className="berpo_btn" href="#/contact">                                    
                                <span className="bp-shape"></span>
                                <span className="bp-shape"></span>                                
                                    <span className="bp-shape"></span>
                                    <span className="bp-shape"></span>
                                    <span className="bp-text">Partner with IITM</span>
                                </a>
                            </div>
                            {/* <div className="accessNav">
                                <a className="berpo_btn" href="#/contact">                                    
                                <span className="bp-shape"></span>
                                <span className="bp-shape"></span>                                
                                    <span className="bp-shape"></span>
                                    <span className="bp-shape"></span>
                                    <span className="bp-text">Get in touch</span>
                                </a>
                            </div> */}
                            <div className="logo">
                                <a href="index.html">
                                    <img src="assets/images/iitmlogo.png" alt="Sociity" />
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header;