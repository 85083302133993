import React, { useState, useEffect } from "react";
import axios from "axios";
import "../SearchResult.css";
import '../SearchCard.css';

function HealthcareContent() {    
    const [lastId, setLastId] = useState(0);   
    const [hasMore, setHasMore] = useState(true);
    const [limit, setLimit] = useState(20);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");    
    const [filterParam, setFilterParam] = useState("");
    const [newParam, setNewParam] = useState("");
    const [count, setCount] = useState(0);
    const [updatedCount, setUpdatedCount] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [users, setUsers] = useState([]);
    const [tempId, setTempId] = useState(0);
    const [inputValue, setInputValue] = useState('');    
    const [menuCondition, setMenuCondition] = useState(true);
    console.log("Menu condition", menuCondition);
    let lastsegment;

    useEffect(() => {
        const url = window.location.href;
        const array = url.split('/');
        const lastsegment = array[array.length - 1];
        console.log("queryParamsarray", lastsegment);
        getUsers(lastsegment);
    }, []);


    const getUsers = async (data) => {
        const response = await axios.get(
            // `https://acr.iitm.ac.in/api-https/sociityApi/emp/read.php?search_query=${keyword}&lastId=${lastId}&limit=${limit}&filter=${filterParam}`
            //`https://acr.iitm.ac.in/api-https/sociityApi/emp/read.php?search_query=${lastsegment}`
            //`http://localhost/sociityApi/emp/read.php?search_query=${data}`

            `https://sociity.in/sociityApi/emp/read.php?search_query=${data}`
            //`http://localhost/sociityApi/emp/read.php?search_query=${data}`
        );


        const newUsers = response.data.result;
        // console.log(`http://localhost/sociityApi/emp/read.php?search_query=${data}`)
        console.log('newUsers', newUsers);
        setUsers([...newUsers]);
        setTempId(response.data.lastId);
        setHasMore(response.data.hasMore);
    };

    const fetchMore = () => {
        setLastId(tempId);
    };
    const handleClick = () => {
        getUsers(inputValue);
    }


    return (
        <>


            <section className="singleService01">
                <div className="container-fluid largeContainer">
                    <div className="row">
                        <div className="col-xl-3 col-md-3 col-sm-12 mt-3">
                            <aside className="widget service_widget">
                                <ul>
                                    <li className="active"><a href="#/agriculture">Agriculture & Soil</a></li>
                                    <li><a href="#/education">Education & Skill Development</a></li>
                                    <li><a href="#/energy">Energy & Sustainability</a></li>
                                    <li><a href="#/environment">Environment & Climate Change</a></li>
                                    <li><a href="#/health">Healthcare & Medical Technology</a></li>
                                    <li><a href="#/heritage">Heritage</a></li>
                                    <li><a href="#/sanitation">Sanitation & Waste Management</a></li>
                                    <li><a href="#/urban">Urban Housing & Infrastructure</a></li>
                                    <li><a href="#/water">Water</a></li>                                    
                                    <li><a href="#/women">Women Empowerment</a></li>
                                </ul>
                            </aside>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                            <div className="container-fluid">
                                <h1 className="secTitle10">OPEN PROJECTS</h1>
                            </div>
                            <div className="search-input-container ">
                                <div className='input-wrap'>
                                    <i className="fas fa-search" onClick={handleClick}></i>
                                    <input type="text" name="product-search" id="product-search" placeholder="Enter project/faculty name..."
                                        value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyDown={handleClick} style={{ paddingTop: "1px" }} />
                                    <i className="fas fa-times" onClick={(e) => setInputValue('')} type='submit' style={{ marginRight: "10px" }}></i>
                                    {menuCondition ?
                                        <i className="fas fa-list" onClick={() => setMenuCondition(false)}></i>
                                        
                                        : <i className="fas fa-border-all" onClick={() => setMenuCondition(true)} style={{ marginLeft: "8px" }}></i>
                                    }
                                </div>
                            </div>

                            {menuCondition ? <div className="container gy-1 mt-4">
                                <div className="row gy-3">

                                    {users.map((user, index) => (
                                        <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: "3px" }}>
                                            <article className="postcard dark blue" key={index} style={{ height: "90%" }}>
                                                <div className="card">
                                                    <div className="cardimg" style={{ width: "100%", height: "180px" }}>
                                                        <a className="postcard__img_link" href={`#/Poster/${user.faculty_proposal_id}`} style={{ height: "35%", margin: "3%" }}>
                                                            <img
                                                                src={user.projectimage}
                                                                className="card-img-top"
                                                                alt="projectimage"
                                                                style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "10px", padding: "5px" }} /></a>
                                                    </div>
                                                    <div className="card-body" style={{ padding: "28px 20px" }}>
                                                        <h5 className="card-title" style={{ textAlign: "justify", minHeight: "100px" }}><a href={`#/Poster/${user.faculty_proposal_id}`}>{user.title}<br /></a></h5>
                                                        <div style={{ minHeight: "50px", margin: "11px 0 11px" }}>  
                                                            <p style={{ fontSize: "16px", color: "blue", fontWeight: "600", textTransform: "capitalize", textAlign: "center", height: "15%" }}>
                                                            {(user.created_by.toUpperCase() === "IITM" || 
                                                            user.created_by.toUpperCase() === "CUBE - IITM" || 
                                                            user.created_by.toUpperCase() === "DEPT. OF CHEMICAL ENGINEERING" || 
                                                            user.created_by.toUpperCase() === "IITM SHAASTRA" || 
                                                            user.created_by.toUpperCase() === "NPTEL" || 
                                                            user.created_by.toUpperCase() === "RUTAG" || 
                                                            user.created_by.toUpperCase() === "The Energy Centre".toUpperCase() || 
                                                            user.created_by.toUpperCase() === "IITM Pravartak".toUpperCase() || 
                                                            user.created_by.toUpperCase() === "Centre of Non-Destructive Evaluation, IITM".toUpperCase()) ? user.created_by : `Prof. ${user.created_by}` }
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: "center", margin: "3%" }}>
                                                            <a href={`#/Poster/${user.faculty_proposal_id}`} className="btn btn-primary" style={{ backgroundColor: "#387710", color: "#fff", boxShadow: "none" }}>
                                                                Get proposal details
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                </div>
                            </div>

                                : <div className="container largeContainer gy-3 mt-5">
                                    <table className="table table-bordered" >
                                        <thead className="bg-light">
                                            <tr style={{ textAlign: "center" }}>
                                                <th>Sl. No</th>
                                                <th>Proposal Title</th>
                                                <th>Faculty Name</th>
                                                <th>View Details</th>

                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {users.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.title}</td>
                                                    <td>{(user.created_by.toUpperCase() === "IITM" || 
                                                            user.created_by.toUpperCase() === "CUBE - IITM" || 
                                                            user.created_by.toUpperCase() === "DEPT. OF CHEMICAL ENGINEERING" || 
                                                            user.created_by.toUpperCase() === "IITM SHAASTRA" || 
                                                            user.created_by.toUpperCase() === "NPTEL" || 
                                                            user.created_by.toUpperCase() === "RUTAG" || 
                                                            user.created_by.toUpperCase() === "The Energy Centre".toUpperCase() || 
                                                            user.created_by.toUpperCase() === "IITM Pravartak".toUpperCase() || 
                                                            user.created_by.toUpperCase() === "Centre of Non-Destructive Evaluation, IITM".toUpperCase()) ? user.created_by : `Prof. ${user.created_by}` }</td>
                                                    <td>
                                                        <div className="d-grid d-md-flex justify-content-center mb-3">
                                                            <a href={`#/Poster/${user.faculty_proposal_id}`}>
                                                                <button className="btn btn-success" style={{ background: "#387710", boxShadow: "none" }}>Details</button>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default HealthcareContent;