import React from 'react'
import "./KeyThemes.css";

function KeyThemes() {
  return (
<div id="content">
      <div id="horz-list">
        <ul>
          <li><a href="#/agriculture">Agriculture & Soil</a></li>
          <li><a href="#/education">Education & Skill Development</a></li>
          <li><a href="#/energy">Energy & Sustainability</a></li>
          <li><a href="#/environment">Environment & Climate Change</a></li>
          <li><a href="#/health">Healthcare & Medical Technology</a></li> 
        </ul>
         <ul>
         <li><a href="#/heritage">Heritage</a></li>
          <li><a href="#/sanitation">Sanitation & Waste Management</a></li>
          <li><a href="#/urban">Urban Housing & Infrastructure</a></li>
          <li><a href="#/water">Water</a></li>
          <li><a href="#/women">Women Empowerment</a></li>
        </ul>
        {/*<ul>
          <li><a href="/">Healthcare & Medical Technology</a></li> 
          <li><a href="/">Heritage</a></li>
          <li><a href="/">Sanitation & Waste Management</a></li>
          <li><a href="/">Urban Housing & Infrastructure</a></li>
          <li><a href="/">Water</a></li>
          <li><a href="/">Women Empowerment</a></li>
        </ul> */}
      </div>
    </div>
  )
}

export default KeyThemes
